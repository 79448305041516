import { defaults } from 'options-defaults';
import { defaultEnv } from './env.default';

const environmentEnv = {
    production: true,
    apiUrl: `$API_URL`,
    staticContentPath: `$STATIC_CONTENT_PATH`,
};

window.env = defaults(defaultEnv, environmentEnv);
